import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

class PromiseButton extends React.Component {
  state = {
    loading: false,
  }

  componentWillUnmount = () => {
    this.unmounted = true;
  }

  onProcess = e => {
    e.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });

    return this.props.onProcess()
      .finally(result => {
        !this.unmounted && this.setState({ loading: false });
        return result;
      });
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    return (
      <span className={classes.wrapper}>
        <Button
          {...this.props.buttonProps}
          disabled={loading || this.props.buttonProps.disabled}
          onClick={this.props.onProcess ? this.onProcess : this.props.onClick}
          style={this.props.buttonStyles}
        >
          { this.props.children }
        </Button>
        {loading && <CircularProgress size={24} {...this.props.progressProps} className={classes.progress} style={this.props.progressStyles}  />}
      </span>
    );
  }
}

PromiseButton.defaultProps = {
  buttonProps: {
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    type: 'submit',
  },
  progressProps: {
    color: 'primary',
  },
  buttonStyles: {},
  progressStyles: {},
  onClick: () => {},
  onProcess: Promise.resolve(),
  onProcessEnd: () => {},
  loading: false
};


const styles = theme => ({
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});



export default withStyles(styles)(PromiseButton);