import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withContext } from 'kn-react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import HomeIcon from '@material-ui/icons/Home';
import CallIcon from '@material-ui/icons/Call';
import StarIcon from '@material-ui/icons/StarRate';
import PublicIcon from '@material-ui/icons/Public';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

// Settings
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';

import AppDrawerBrand from './AppDrawerBrand/AppDrawerBrand';
import AppDrawerLink from './AppDrawerLink/AppDrawerLink';
import AppDrawerLinkGroup from './AppDrawerLinkGroup/AppDrawerLinkGroup';

import { ThemeContext } from '../../Theme/ThemeProvider';


class AppDrawer extends React.Component {

  closeDrawer = () => {
    this.props.toggleDrawer(false);
  }

  render() {
    const { links, classes, user, poweredBy } = this.props;

    return (
      <div>
        <Drawer open={this.props.open} onClose={this.closeDrawer}>
          <div
            tabIndex={0}
            role="button"
          >
            <AppDrawerBrand
              appLogo={this.props.theme.appLogo}
              appLogoStyles={this.props.theme.appLogoStyles}
              appTitle={this.props.theme.appTitle}
              closeDrawer={this.closeDrawer}
              email={user.email}
            />
            <div className={classes.list}>
              <List>
                {
                  links.map((l,i) => {
                    if(l.links) {
                      return (
                        <React.Fragment key={i}>
                          <Divider/>
                          <AppDrawerLinkGroup
                            {...l}
                          />
                        </React.Fragment>
                      )
                    } else if (l.divider){
                      return <Divider key={i} />;
                    } else {
                      return <AppDrawerLink key={i} {...l}/>;
                    }
                })}

              </List>

              <div className={classes.poweredBy}>
                {poweredBy || <span>Powered by Go<strong>Boost</strong></span>}
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}


AppDrawer.defaultProps = {
  links: [
    { text: 'Home', path: '/', icon: HomeIcon, exactPathMatch: true },
    { text: 'Sites', path: '/sites', icon: PublicIcon },
    { text: 'Ads', path: '/ads', icon: SpeakerNotesIcon },
    { text: 'Reviews', path: '/reviews', icon: StarIcon },
    { text: 'Settings', icon: SettingsIcon, children: [
        { text: 'Business Profile', path: '/business_profile', icon: BusinessIcon },
        { text: 'Users', path: '/users', icon: PeopleIcon },
        { text: 'Credit Card', path: '/credit_card', icon: AttachMoneyIcon },
        { text: 'Transactions', path: '/transactions', icon: HistoryIcon },
      ]
    }
  ]
}


const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  poweredBy: {
    fontSize: 10,
    textAlign: 'center',
    padding: 15
  },
};

export default withStyles(styles)(withContext(ThemeContext, AppDrawer));