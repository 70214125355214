import React from 'react';

import { SvgIcon } from '@material-ui/core';


const ownHomeIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M19 12h2l-9-9l-9 9h2v7a2 2 0 0 0 2 2h5" stroke={props.fill}/>
      <circle cx="19" cy="19" r="3" stroke={props.fill}/>
      <path d="M9 21v-6a2 2 0 0 1 2-2h2c.641 0 1.212.302 1.578.771" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


ownHomeIcon.defaultProps = {
  fill: '#5E5F64'
}


export default ownHomeIcon;