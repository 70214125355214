/*global window*/
import React from 'react';
import storageAvailable from 'storage-available';
import Axios from 'axios';

import { withContext, log } from 'kn-react';
import { CircularProgress, LinearProgress } from '@material-ui/core';

import { AuthContext } from '../Auth/AuthProvider';
import UserRoleModel from './UserRoleModel';
import SelectUserRole from './SelectUserRole';
import ImpersonatingFooter from './ImpersonatingFooter';


const localStorageAvailable = storageAvailable('localStorage');
const TRUE_USER_ROLE_ID_KEY = 'trueUserRoleId';
const CURRENT_USER_ROLE_KEY = 'currentUserRole';


export const removeStoredCurrentUserRoleId = () => {
  if(localStorageAvailable) {
    localStorage.removeItem(TRUE_USER_ROLE_ID_KEY);
    localStorage.removeItem(CURRENT_USER_ROLE_KEY);
  }
}


const DEFAULT_STATE = {
  userRoleContextLoading: true,
  didCheckLocalStorage: false,
  userRoles: [],
  trueUserRole: {},
  currentUserRole: {},
};


export const UserRoleContext = React.createContext(DEFAULT_STATE);

// When you switch roles, we need to load the properties of the role you just signed in as...


class UserRoleProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchUserRoles()
      .then(this.loadStoredState);
  }


  fetchUserRoles = () => {
    let url = '/api/core/user_roles';

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }

    return Axios.get(
      url,
      { headers: this.props.getAuthHeaders() }
    ).then(response => {
      const userRoles = response.data.user_roles.map(u => UserRoleModel.fromJSON(u));
      log('fetchUserRoles', userRoles);
      this.setState({ userRoleContextLoading: false, userRoles });
    }).catch(error => {
      log('error', error);
      this.setState({ userRoleContextLoading: false, userRoles: [] });
    });
  }


  loadStoredState = () => {
    let trueUserRoleId;
    let currentUserRole;


    return Promise.resolve().then(() => {
      if( !this.props.fetchStoredTrueUserIdPromise ){
        trueUserRoleId = fetchStoredTrueUserId();
      } else {
        return this.props.fetchStoredTrueUserIdPromise(
          this
        ).then(_trueUserRoleId => {
          trueUserRoleId = _trueUserRoleId;
        });
      }
    }).then(() => {
      if( !this.props.fetchStoreCurrentUserRolePromise ){
        currentUserRole = fetchStoreCurrentUserRole();
      } else {
        return this.props.fetchStoreCurrentUserRolePromise(
          this
        ).then(_currentUserRole => {
          currentUserRole = _currentUserRole;
        });
      }
    }).then(() => {
      let newState = {
        didCheckLocalStorage: true
      };

      if( trueUserRoleId ) {
        const { userRoles } = this.state;
        const trueUserRole = userRoles.find(u => u.id === trueUserRoleId );
        newState = {
          ...newState,
          trueUserRole,
          currentUserRole: trueUserRole,
        };
      }

      if( currentUserRole ) {
        newState = {
          ...newState,
          currentUserRole: UserRoleModel.fromJSON(JSON.parse(currentUserRole))
        };
      }

      this.setOnWindow(newState);
      this.setState(newState);
    });
    // return initialPromise().then(() => {
    //   log('calling loadStoredState');
    //   log('trueUserRoleId', trueUserRoleId);
    //   log('currentUserRole', currentUserRole);


    //   let newState = {
    //     didCheckLocalStorage: true
    //   };

    //   if( trueUserRoleId ) {
    //     const { userRoles } = this.state;
    //     const trueUserRole = userRoles.find(u => u.id === trueUserRoleId );
    //     newState = {
    //       ...newState,
    //       trueUserRole,
    //       currentUserRole: trueUserRole,
    //     };
    //   }

    //   if( currentUserRole ) {
    //     newState = {
    //       ...newState,
    //       currentUserRole: UserRoleModel.fromJSON(JSON.parse(currentUserRole))
    //     };
    //   }

    //   this.setOnWindow(newState);
    //   this.setState(newState);
    // });
  }


  setUserRole = userRole => {
    const newState = { currentUserRole: userRole, trueUserRole: userRole };
    this.setOnWindow(newState);
    this.setState(newState);


    const trueUserRoleId = userRole.id;
    const currentUserRole = JSON.stringify( userRole );

    if( this.props.setUserRole ){
      this.props.setUserRole({
        [ TRUE_USER_ROLE_ID_KEY ]: trueUserRoleId,
        [ CURRENT_USER_ROLE_KEY ]: currentUserRole
      });
    } else if( localStorageAvailable ) {
      localStorage.setItem(TRUE_USER_ROLE_ID_KEY, trueUserRoleId);
      localStorage.setItem(CURRENT_USER_ROLE_KEY, currentUserRole);
    }

    // this.props.history.push('/');
  }


  setOnWindow = props =>{
    if (
      typeof window !== 'undefined' &&
      window.location.hostname.endsWith('localhost')
    ) {
      Object.keys(props).forEach(k => window[k] = props[k]);
    }
  }


  getUserRoleAuthHeaders = () => {
    return getUserRoleAuthHeaders({
      authHeaders: this.props.getAuthHeaders(),
      currentUserRoleId: this.state.currentUserRole.id,
      trueUserRoleId: this.state.trueUserRole.id,
    });
  }

  hasRole = () => {
    const { currentUserRole } = this.state;
    return currentUserRole && currentUserRole.id;
  }

  isImpersonatingRole = () => {
    const { currentUserRole, trueUserRole } = this.state;
    return (
      this.hasRole() &&
      currentUserRole &&
      trueUserRole &&
      currentUserRole.id !== trueUserRole.id
    );
  }

  startImpersonatingRole = userRole => {
    const newState = { currentUserRole: userRole };


    const currentUserRoleKey = JSON.stringify( userRole );

    if( this.props.startImpersonatingRole ){
      this.props.startImpersonatingRole( currentUserRoleKey );
    } else if( localStorageAvailable ){
      localStorage.setItem(CURRENT_USER_ROLE_KEY, currentUserRoleKey);
    }

    this.setOnWindow(newState);
    this.setState(newState);
  }

  stopImpersonatingRole = () => {
    const { currentUserRole } = this.state;
    log('stopImpersonatingRole', currentUserRole);

    const newState = { currentUserRole: this.state.trueUserRole };
    this.setOnWindow(newState);
    this.setState(newState);
    this.setUserRole(this.state.trueUserRole);


    if( this.props.stopImpersonatingRole ){
      this.props.stopImpersonatingRole(
        currentUserRole.userId
      );
    } else if(
      !this.props.createStopImpersonatingUrl &&
      this.props.history
    ){
      this.props.history.push('/');
    } else if( this.props.history ) {
      this.props.history.push(
        this.props.createStopImpersonatingUrl(
          currentUserRole.userId
        )
      );
    }
  }


  updateUserProfile = updatedAttributes => {
    const id = this.state.currentUserRole.userId;
    const user = new UserRoleModel(updatedAttributes);


    let url = `/api/core/users/${ id }`;

    if( this.props.baseUrl ){
      url = `${ this.props.baseUrl }${ url }`;
    }


    return Axios.put(
      url,
      { user },
      { headers: this.getUserRoleAuthHeaders()}
    ).then(response => {
      const mapping = {'firstName':'userFirstName','lastName':'userLastName','email':'userEmail'};
      let currentUserRole = {...this.state.currentUserRole};
      Object.keys(updatedAttributes).forEach(attr => {
        if (attr in mapping) {
          currentUserRole[mapping[attr]] = updatedAttributes[attr];
        }
      })
      this.setState({ currentUserRole });
    }).catch(error => {
      throw error
    });
  }


  wrapImpersonatingFooter = content => {
    const { currentUserRole } = this.state;
    return (
      <div style={{ marginBottom: 60 }}>
        { content }
        <ImpersonatingFooter
          loggedInAs={ `${currentUserRole.userEmail}, ${currentUserRole.roleName} - ${currentUserRole.roleTypeName}` }
          stopImpersonating={this.stopImpersonatingRole}
        />
      </div>
    );
  }


  render(){
    const { userRoleContextLoading, userRoles, currentUserRole, didCheckLocalStorage } = this.state;

    if( userRoleContextLoading && this.props.isLinearProgressDisabled ){
      return <React.Fragment></React.Fragment>;
    } else if( userRoleContextLoading ) {
      return <LinearProgress/>;
    }

    let content;
    if(
      this.hasRole() &&
      this.props.roleProvider &&
      this.props.roleProvider(currentUserRole)
    ){
      const RoleProvider = this.props.roleProvider(currentUserRole);
      content = (
        <RoleProvider isLinearProgressDisabled={this.props.isLinearProgressDisabled}>
          { this.props.children }
        </RoleProvider>
      );
    } else if( this.hasRole() ){
      content = this.props.children;
    }
    else {
      if(didCheckLocalStorage) {
        content = (
          <SelectUserRole
            userRoles={userRoles}
            getAuthHeaders={this.props.getAuthHeaders}
            setUserRole={this.setUserRole}
          />
        );
      } else {
        content = <CircularProgress/>;
      }
    }

    return (
      <UserRoleContext.Provider
        value={{
          userRoles: this.state.userRoles,
          trueUserRole: this.state.trueUserRole,
          currentUserRole: this.state.currentUserRole,
          fetchUserRoles: this.fetchUserRoles,
          setUserRole: this.setUserRole,
          getUserRoleAuthHeaders: this.getUserRoleAuthHeaders,
          isImpersonatingRole: this.isImpersonatingRole,
          startImpersonatingRole: this.startImpersonatingRole,
          stopImpersonatingRole: this.stopImpersonatingRole,
          removeStoredCurrentUserRoleId: this.removeStoredCurrentUserRoleId,
          updateUserProfile: this.updateUserProfile
        }}
      >
        {
          this.isImpersonatingRole() ?
            this.wrapImpersonatingFooter(content)
          :
            content
        }
      </UserRoleContext.Provider>
    );
  }
}



UserRoleProvider.defaultProps = {
  history: {},
  roleProvider: null,
  createStopImpersonatingUrl: currentUserRoleUserId => {},
  fetchStoredTrueUserIdPromise: null,
  fetchStoreCurrentUserRolePromise: null,
  setUserRole: null,
  startImpersonatingRole: null,
  stopImpersonatingRole: null,
  baseUrl: null,
  isLinearProgressDisabled: false
}



export default withContext(AuthContext, UserRoleProvider);



export const fetchStoredTrueUserId = () => {
  if( localStorageAvailable ) {
    return +localStorage.getItem(TRUE_USER_ROLE_ID_KEY);
  }


  return {};
}



export const fetchStoreCurrentUserRole = () => {
  if( localStorageAvailable ) {
    return localStorage.getItem(CURRENT_USER_ROLE_KEY);
  }


  return {};
}



export const getUserRoleAuthHeaders = params => {
  if( !params ){
    return {};
  }

  const { authHeaders, currentUserRoleId, trueUserRoleId } = ( params || {} );

  if(
    !(
      authHeaders &&
      currentUserRoleId &&
      trueUserRoleId
    )
  ){
    return;
  }


  return {
    ...authHeaders,
    'current-user-role-id': currentUserRoleId,
    'true-user-role-id': trueUserRoleId,
  }
}