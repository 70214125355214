import React from 'react';

import { SvgIcon } from '@material-ui/core';


const homeEnergyIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M20.136 11.136L12 3l-9 9h2v7a2 2 0 0 0 2 2h7" stroke={props.fill}/>
      <path d="M9 21v-6a2 2 0 0 1 2-2h2c.467 0 .896.16 1.236.428M19 22v.01M19 19a2.003 2.003 0 0 0 .914-3.782a1.98 1.98 0 0 0-2.414.483" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


homeEnergyIcon.defaultProps = {
  fill: '#5E5F64'
}


export default homeEnergyIcon;