import React from 'react';

import areAdsBlocked from '../UtilityFunctions/AreAdsBlocked';
import { Dialog, DialogContent, DialogActions, DialogContentText, Button, Typography } from '@material-ui/core';


const DEFAULT_STATE = {
  areAdsBlocked: false,
  dialogOpen: false
};


export const AdBlockerDetectorContext = React.createContext(DEFAULT_STATE);


class AdBlockerDetectorProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    // Show a message to recommend to turn off a customer's Ad Blocker
    // if ads are blocked and there is not a message already being
    // shown (to not interfere with earlier messages).
    if(
      areAdsBlocked()
    ){
      this.setState(
        {
          areAdsBlocked: true,
          dialogOpen: true
        }
      );
    };
  }


  onClose = () => {
    this.setState({ dialogOpen: false });
  }


  render(){
    return (
      <AdBlockerDetectorContext.Provider
        value={{
          areAdsBlocked: this.state.areAdsBlocked
        }}
      >
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.onClose}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogContent>
            <DialogContentText>
              <Typography variant='body1'>
                We noticed that you are using an Ad Blocker.
                Please note that your Ad Blocker may interfere with this application.
                We recommend that you disable your Ad Blocker for this application and
                refresh the page.
              </Typography>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.onClose} variant="outlined" color="primary">
              Close
            </Button>
          </DialogActions>

        </Dialog>

        { this.props.children }
      </AdBlockerDetectorContext.Provider>
    );
  }
}


export default AdBlockerDetectorProvider