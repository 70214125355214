import React from 'react';
import { MaxWidth } from 'kn-react';
import { Typography } from '@material-ui/core';

import UserRoleList from './userRoleList';

export default props => {
  if(props.userRoles.length === 1) {
    props.setUserRole(props.userRoles[0]);
  }

  return (
    <MaxWidth maxWidth={310} style={{ padding: 20 }}>
      <Typography variant="h6">
        Select your role:
      </Typography>
      <UserRoleList userRoles={props.userRoles} setUserRole={props.setUserRole}/>
    </MaxWidth>
  )
}