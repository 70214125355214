import React from 'react';
import storageAvailable from 'storage-available';

import { LinearProgress } from '@material-ui/core';

import CredentialsModel from './CredentialsModel';


const localStorageAvailable = storageAvailable('localStorage');


export const getStoredCredentials = () => {
  if( !localStorageAvailable ){
    return {};
  }


  const credentials = localStorage.getItem('credentials') || null;
  return CredentialsModel.fromJSON( JSON.parse(credentials) );
};

const storedCredentials = getStoredCredentials() || {};

const DEFAULT_STATE = {
  credentials: storedCredentials || null,
  authContextLoading: false,
};


export const AuthContext = React.createContext(DEFAULT_STATE);

 class AuthProvider extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      ...DEFAULT_STATE,
      authContextLoading: Boolean(
        props.fetchDefaultCredentialsPromise
      )
    };
  }


  componentDidMount = () => {
    if( this.props.fetchDefaultCredentialsPromise ){
      return this.props.fetchDefaultCredentialsPromise().then(
        credentials => this.setState({ credentials, authContextLoading: false })
      );
    }
  }


  setCredentials = credentials => {
    this.setState({ credentials });

    const jsonCredentials = JSON.stringify(
      credentials
    );

    if( this.props.setCredentials ){
      this.props.setCredentials( jsonCredentials );
    } else if( localStorageAvailable ) {
      localStorage.setItem('credentials', jsonCredentials);
    }
  }

  removeCredentials = () => {
    this.setState({ credentials: null });

    if( this.props.removeCredentials ){
      this.props.removeCredentials();
    } else if( localStorageAvailable ) {
      localStorage.removeItem('credentials');
    }
  }

  getAuthHeaders = () => {
    return getAuthHeaders(
      this.state.credentials
    );
  }


  hasToken = () => {
    const { credentials } = this.state;
    return credentials && credentials.authToken;
  }


  render(){
    if( this.state.authContextLoading && this.props.isLinearProgressDisabled ){
      return <React.Fragment></React.Fragment>;
    } else if( this.state.authContextLoading ) {
      return <LinearProgress/>;
    }


    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          setCredentials: this.setCredentials,
          removeCredentials: this.removeCredentials,
          getAuthHeaders: this.getAuthHeaders,
          hasToken: this.hasToken,
        }}
      >
        { this.props.children }
      </AuthContext.Provider>
    );
  }
}



AuthProvider.defaultProps = {
  setCredentials: null,
  removeCredentials: null,
  fetchDefaultCredentialsPromise: null,
  isLinearProgressDisabled: false
}


export default AuthProvider;



export const getAuthHeaders = credentials => ({
  'Authorization': `Bearer ${ credentials.authToken }`
});