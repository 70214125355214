import React from 'react';

import { SvgIcon } from '@material-ui/core';


const homeEnergyIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="m21 12l-9-9l-9 9h2v7a2 2 0 0 0 2 2h4.7" stroke={props.fill}/>
      <path d="M9 21v-6a2 2 0 0 1 2-2h2" stroke={props.fill}/>
      <circle cx="18" cy="18" r="3" stroke={props.fill}/>
      <path d="M20.2 20.2L22 22" stroke={props.fill}/>
    </g>
  </SvgIcon>
);


homeEnergyIcon.defaultProps = {
  fill: '#5E5F64'
}


export default homeEnergyIcon;