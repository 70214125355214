import React from 'react';

import { SvgIcon } from '@material-ui/core';


const buyingHomeIcon = props => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{ fontSize: props.fontSize }}
  >
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path stroke={props.fill} d="m19 10l-7-7l-9 9h2v7a2 2 0 0 0 2 2h6"/>
      <path stroke={props.fill} d="M9 21v-6a2 2 0 0 1 2-2h2c.387 0 .748.11 1.054.3M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H17m2 0v1m0-8v1"/>
    </g>
  </SvgIcon>
);


buyingHomeIcon.defaultProps = {
  fill: '#5E5F64'
}


export default buyingHomeIcon;